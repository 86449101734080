.GeneratorNew {   }

.newGenScroll { overflow:hidden; display:flex; align-items:start; flex:1; height:100%; } 

.newGenCatsBox { white-space:wrap; display:flex; gap:2%; flex-wrap: wrap; padding:20px; min-width:100%; flex:1; margin-bottom:30px; }

.newGenCatsBox a { display:flex; gap:10px; max-width:48%; margin-top:10px; align-self:flex-start; border:1px solid #ddd; border-radius: 10px; }
.newGenCatsBox a img { width:100px; border-radius: 10px 0 0 10px; object-fit: cover; border-right:1px solid #ddd; }
.newGenCatsBox a label { padding:5px; }
.newGenCatsBox a label h3 { font-size:13pt; font-weight: 600; }
.newGenCatsBox a label p { font-size: 9pt; color:#888 }

.newGenInput {  min-width:100%; flex:1; }