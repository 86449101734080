.articleBox { background-color: #fff; width:100%; max-width:900px; background-color: #fff; box-shadow: 0 0 10px rgba(0,0,0,0.1); min-height:200px; margin:auto; margin-top:-70px; padding:20px 30px; }
.articleBox h2 { text-align:start; font-size: 22pt;
    font-weight: 800;
    margin-bottom: 5px;
    color: #fd745a; }

.articleBox .fullBox { text-align:start; margin-top:25px; }
.articleBox .fullBox * { font-size:13pt; line-height: 190%; font-weight: 300; color:#444 }
.articleBox .fullBox p { margin-bottom:15px; }

.articleBox form { background-color: #f9f9f9; display:table; width:100%; max-width: 440px; padding:10px 20px; border:1px solid #eee; border-radius: 10px; margin-top:50px; }
.articleBox form p { color:#fd745a }
.articleBox form .btn { cursor: pointer; padding:5px 15px; font-size:12pt; margin-left:10px; }
.articleBox form .btn:hover { opacity:0.8 }
