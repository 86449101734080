.bookListBox { display:flex; flex-wrap: wrap; gap:2%; padding:30px; }
.bookListBox .bookBox { width:23.4%; box-shadow:0 0 7px rgba(0,0,0,0.1); position:relative; margin-top:2%; }
.bookListBox .bookBox img { width:100%; aspect-ratio: 16/22; object-fit: cover; }
.bookListBox .bookBox nav { padding:0 10px 10px 10px; }
.bookListBox .bookBox h3 { font-size: 10pt; font-weight: 800; }
.bookListBox .bookBox h4 { font-size: 10pt; font-weight: 300; color:#888 }

.bookListBox .bookBox span { position:absolute; top:10px; right:0; background-color: #1f66d8; padding:4px 10px; font-size: 8pt; color:#fff; font-weight: 800; }

.bookHeaderBox { padding:0 15px; background-color: #111; display:flex; justify-content: space-between; align-items: center; position:sticky; top:0; right:0; margin-bottom:20px; }
.bookHeaderBox ul.right { display:flex; align-items: center; gap:10px; }
.bookHeaderBox ul.right a { line-height: 100%; }
.bookHeaderBox ul.right a:hover svg { color:#fff; opacity:1; }
.bookHeaderBox ul.right a svg { font-size: 22pt; color:#ccc; }
.bookHeaderBox ul.right a svg { font-size: 22pt; color:#ccc; }

.bookHeaderBox .left .outterUserBox { position:relative; padding:15px; }
.bookHeaderBox .left .outterUserBox:hover { background-color: #222; }
.bookHeaderBox .left .outterUserBox:hover .userMenuBox { display:flex; }
.bookHeaderBox .left b { color:#fff; font-size: 10pt; font-weight: 800; padding:15px; display:block; }
.bookHeaderBox .left .userMenuBox { display:flex; }
.bookHeaderBox .left .mainLink { display:flex; align-items: center; gap:5px; color:#ddd }
.bookHeaderBox .left .mainLink img { width:30px; height:30px; border-radius: 50%;  }
.bookHeaderBox .left .userMenuBox { display:none; position:absolute; flex-direction: column; left:0; top:60px; background-color: #222; width:200px; padding:10px 0; }
.bookHeaderBox .left .userMenuBox a { padding:5px 10px; display: flex; align-items: center; gap:5px; color:#eee; font-size: 10pt; font-weight: 800; }

.bookHeaderBox h1 { font-size: 12pt; font-weight: 800; color:#fff }

.bookPagingBox { position:fixed; bottom:0; right:0; left:0; margin:auto; width:120px; background-color: #111; padding:10px; border-radius:30px 30px 0 0; display:flex; align-items: center; justify-content: center; }
.bookPagingBox input { padding:6px; font-size: 12pt; text-align: center; width:80px; border-radius: 10px; border:0; }

.bookPagesListBox { display:flex; flex-direction: column; justify-content: center; align-items: center; gap:10px; }
.bookPagesListBox div.page { background-color: #fff; padding:4px; box-shadow: 0 0 20px rgba(0,0,0,0.2); }
.bookPagesListBox div.page img { display:block }