.firstElem.stats .mainWidth { border-top:3px solid #1ccf93 }
.firstElem.stats section {  gap:15px; padding:30px 5%; }
.firstElem.stats section svg.Icon { background-color: #ffeae6; color:#ff6c74; padding:10px; font-size:30pt; border-radius: 50%; }
.firstElem.stats section h3 { font-size: 46pt; line-height: 100%; font-weight: 300; }
.firstElem.stats section b { font-size:10pt; font-weight: 300; line-height: 120%; color:#999 }

.firstElem.stats section span { margin-top:4px; display:flex; align-items: center; justify-content: center; border-radius: 20px; background-color:#fee6e8; color:#b93336; font-weight: 600; font-size:8pt; gap:2px; width:56px; }
.firstElem.stats section span svg { font-size:7pt; color:#b93336;  }

.firstElem.stats section span.up { background-color:#e5fdf5; color:#34a57f; }
.firstElem.stats section span.up svg { color:#34a57f; }

.containerBox { background-color: #fff; box-shadow: 0 0 10px rgba(0,0,0,0.1);  height:fit-content }

.cp { display:flex; margin-top:20px; gap:20px; flex-basis:content; }
.cp .containerBox .boxTitle { padding:0 10px; font-size:15pt; }
.cp .containerBox .boxTitle .btnFilter { font-size: 16pt; line-height:0; padding:4px;border-radius: 5px; background-color: #00b5c0; color:#fff; }
.cp .containerBox .boxLine { border-color: #1ccf93; }

.cp .containerBox .profile { margin-top:20px; }
.cp .containerBox .profile .pImg { display:flex; align-items: center; flex-direction: column; justify-content: center; margin-bottom:50px; }
.cp .containerBox .profile .pImg img { border-radius: 50%; width:100px; height:100px; }
.cp .containerBox .profile .pImg h5 { font-size:11pt; color:#777 }

.cp .containerBox .profile li { padding:5px 25px; display:flex; justify-content: space-between; }
.cp .containerBox .profile li:nth-child(even) { background-color: #f6f6f6; }

.cp .containerBox .profile li span { font-weight: 300; color:#777 }
.cp .containerBox .profile li b { color:#156148 }
.cp .containerBox .profile li span, .cp .containerBox .profile li b { font-size:10pt; flex:1; }

.quizzesList { padding:10px; margin-top:20px; }
.quizzesList table {  width:100%; }
.quizzesList table thead { background-color: #f8f8f8; }
.quizzesList table thead td { border-bottom: 1px solid #ddd;}
.quizzesList table tr.checked { background-color: #ffeedc; }
.quizzesList table tr.checked td { border-color: #dad5be;  }
.quizzesList table td { border-bottom:1px solid #eee; padding:20px 8px; text-align: center; font-size: 10pt; color:#555;  }

.quizzesList table td b { font-size:12pt; font-weight: 600; }
.quizzesList table td p { font-size:10pt; font-weight: 300; }

.quizzesList table tr:nth-child(even) { background-color: #f9f9f9; }

.quizInfoBox { margin-top:40px; }
.quizInfoBox a { display:flex; align-items: center; border-top:1px solid #e5e5e5; }
.quizInfoBox a:nth-child(odd) { background-color: #f7f7f7; }
.quizInfoBox a span { padding:10px; width:30%; font-weight: 500; font-size:11pt; }
.quizInfoBox a b { display:block; flex:1; padding:10px; font-size:11pt; border-left:1px solid #e5e5e5; color:#555; }

.quizInfoBox ul { display:table; margin:auto; text-align: center; }
.quizInfoBox ul img { border-radius:50%; width:150px; aspect-ratio: 1; object-fit: cover; }
.quizInfoBox ul h3 { font-size:12pt; font-weight: 600; }
.quizInfoBox ul h4 { font-size:11pt; font-weight:300; color:#777 }

.questionComments .btn { padding:4px 10px; margin:auto; font-size:12pt; cursor: pointer; }

.filterBox { padding:0 20px; background-color: #f8f8f8; border-radius:10px 10px 0 0;   display:none; gap:20px; align-items: center; }
.filterBox.open { padding:30px 20px; height:auto; border-bottom:1px solid #bed3e3; display:flex; }

.errorBox { padding:30px; text-align: center; font-size:14pt; color:#777 }

.checkboxFav:focus {  box-shadow:inset 0px 0px 0px 2px #000 !important;}

.searchTasksBox { display:flex; align-items: center; gap:10px; }
.searchTasksBox .selectBox { width:200px; }
.searchTasksBox .selectBox .selectName { background-color: #f5f5f5; padding:5px; }

.titleWithFilter { padding:15px;  }
.titleWithFilter .formInputBox { width:200px;  }
.titleWithFilter .formInputBox .selectName { background-color: #f5f5f5; padding:10px 5px; }