.body.taskBox { width:100vw; height:calc(100vh - 81px); overflow:auto; background-color: #f1f1f1; padding-bottom:20px; }
.body.taskBox .mainWidth { max-width: 700px; }
.body.taskBox .containerBox { border-radius: 30px; min-height:400px; flex:1; margin-top:40px; }

.body.taskBox  h1 { font-size:14pt; background-color: #00b4c1; color:#fff; padding:15px 20px; border-radius:30px 30px 0 0; display:flex; justify-content: space-between; align-items: center;  }
.body.taskBox  .sectionInfo .teeacherInfo { gap:10px; display:flex; padding:20px; border-bottom:1px solid #eee; }
.body.taskBox  .sectionInfo .teeacherInfo img { border-radius: 50%; width:60px; aspect-ratio: 1; }
.body.taskBox  .sectionInfo .teeacherInfo div { display:flex; flex-direction: column; justify-content: center; }
.body.taskBox  .sectionInfo .teeacherInfo div text { display:block; font-weight: 600; color:#555 }
.body.taskBox  .sectionInfo .teeacherInfo div text:nth-child(2){ font-size:10pt; font-weight:300; color:#999 }

.body.taskBox  .sectionInfo ul { display:flex;  gap:13px; flex-wrap: wrap; background-color: #f9f9f9; padding:20px 25px; }
.body.taskBox  .sectionInfo ul li { width:48%; box-shadow:0 0 5px rgba(0,0,0,0.1); color:#888; background-color: #fff; padding:10px; display:flex; align-items: center; gap:5px; border-radius:5px; }
.body.taskBox  .sectionInfo ul li svg { font-size:18pt; color:#207e85;  }
.body.taskBox  .sectionInfo ul li span { font-size:11pt; }
.body.taskBox  .sectionInfo ul li span b { font-size:11pt;  }

.body.taskBox  .btnSection { padding:20px 22px; border-top:1px solid #eee; display:flex; gap:5px; }
.body.taskBox  .btnSection .btn { padding:5px 10px;font-size:12pt; }

.quizHide { display:none; }

.body.taskBox .questionsBox { display:none; position:relative }
.body.taskBox .questionsBox .answerTrueFalse { position:absolute; stroke-width:10px; stroke:#fff; top:-25px; right:0; left:0; font-size:60pt; color:rgb(87, 203, 88); margin:auto; }
.body.taskBox .questionsBox .answerTrueFalse.answerFalse { color:#d23b3b; font-size:80pt; top:-45px; }
.body.taskBox .questionsBox.show { display:block; }

.body.taskBox .questionsBoxQ { padding:30px; }
.body.taskBox .questionsBoxQ .noteBox { margin-bottom:5px; font-size:12pt; font-weight: 300; color:#777 }
.body.taskBox .questionsBoxQ h3 { font-size:13pt; font-weight: 600; color:#555;  }
.body.taskBox .questionsBoxQ .answersBox { margin-top:30px; display:flex; flex-direction: column; gap:8px; margin-bottom:30px; }

.body.taskBox .questionsBoxQ .answersBox a { border-radius: 25px; display:flex; align-items: center; background-color: #f9f9f9; width:50%; padding:10px 8px; border:1px solid #eee; gap:8px; color:#111 }
.body.taskBox .questionsBoxQ .answersBox a svg { font-size:18pt; color:#00b4c1 }
.body.taskBox .questionsBoxQ .answersBox a.checked { background-color: #00b4c1; color:#fff; }
.body.taskBox .questionsBoxQ .answersBox a.checked svg { color:#fff; }

.body.taskBox .qVideoPlayer { padding:20px; }
.body.taskBox .qVideoPlayer iframe { width:100%; aspect-ratio: 16/9; }
.body.taskBox .qVideoPlayer h3 { padding:0; margin-top:5px; }
.body.taskBox .qVideoPlayer p { font-size:11pt; font-weight: 300; color:#777 }

.answerSuccess { display:flex; width:100vw; height:100vh; justify-content: center; align-items: center; }
.answerSuccess div { border-radius: 30px; width:90%; padding:20px; text-align: center; background-color: #fff; box-shadow:0 0 10px rgba(0,0,0,0.1); max-width:450px; }
.answerSuccess div svg { font-size:120pt; color:#00b4c1 }
.answerSuccess div h1 { font-size:14pt; }
.answerSuccess div p { font-size: 12pt; color:#999; }
.answerSuccess div score { display:block; font-size: 40pt; margin-top:30px; color:#00b4c1; line-height: 100%; }
.answerSuccess div score.error { color:red; }
.answerSuccess div b { font-size:11pt; color:#777; font-weight: 300; }

.answerSuccess div .btn { padding:5px 20px; display:table; margin:auto; margin-top:30px; }

.answerSuccess.er div svg { color:#d23b3b }


.answersBox .letSqu { display:flex; margin-top:20px; justify-content:center; gap:0.7%;  }
.answersBox .letSqu span { font-weight: bold; font-size:16pt; color:#028eff; text-transform:uppercase; max-width:45px; flex:1; aspect-ratio: 1; background-color: #f9f9f9; border-bottom:2px solid #e5e5e5;  display:flex; justify-content: center; align-items: center; cursor: pointer; }

.answersBox .letSqu b {  text-transform:uppercase; cursor: pointer; width:47px; aspect-ratio: 1; border-radius:5px; border:2px solid #469be1; margin-top:1%; background-color: #42a5f5; color:#fff;  display:flex; justify-content: center; align-items: center; font-size:20pt; }
.answersBox .letSqu b:hover { opacity:0.8 }

.answersBox .letSqu b.disabled { background-color: #999; border:2px solid #888; }

.answerStats { display:flex; align-items: center; justify-content: center; padding:20px; border-bottom:1px solid #eee; }
.answerStats li { display:flex; flex-direction: column; align-items: center; flex:1; }
.answerStats li span { font-size:27pt; color:#028eff; text-align: center; }
.answerStats li b { font-size:10pt; color:#999 }

.teacherTaskTitle { align-items: center; }
.teacherTaskTitle svg { background-color: rgba(255,255,255,0.2); padding:2px; font-size: 26pt; border-radius: 5px; color:#fff }

.showAllAnswersBox { color:#fff; position:fixed; bottom:10px; right:10px; background-color: #d23b3b; padding:10px; display:flex; align-items: center; gap:5px; z-index:1; border-radius: 10px; }
.showAllAnswersBox svg { font-size:20pt; }