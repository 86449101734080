
.loginForm h2 { text-align: center; font-size: 18pt; font-weight: 800; margin-bottom:5px; color:#fd745a; }
.loginForm p { text-align: center; font-size: 11pt; font-weight: 300; color:#888; margin-bottom:50px; }

.loginForm .btn { width:100%; margin-top:20px; padding:8px; justify-content: center; }
.loginForm .or { width:70%; margin:auto; text-align: center; margin-top:40px; border-top:1px solid #ddd; padding:0 20px;  }
.loginForm .or span { display:table; margin:auto; background-color: #fff; margin-top:-11px; padding:0 10px; font-size:10pt; color:#777 }

.loginForm .btn.extLogin { border:1px solid #ddd; gap:10px; justify-content: center; background-color: #f1f1f1; width:auto; margin:auto; display:table; color:#555; margin-top:30px; display:flex; align-items: center; font-size:12pt; }
.loginForm .extLogin img { height:30px; }

