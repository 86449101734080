.firstElem.search .mainWidth {  height:70px; }

.sect5.tasks .sect5Box { flex-wrap: wrap; gap:1.5% }
.sect5.tasks .sect5Box.just4 div { width:23.5%; flex:none; margin-top:1.5%; }
.sect5.tasks .sect5Box div { width:18.5%; flex:none; margin-top:1.5%; }
.sect5.tasks .sect5Box div img { aspect-ratio: 1.5;  }

.sect5.tasks .sect5Box div p { display:flex; flex-direction: column; align-items: flex-start; }
.sect5.tasks .sect5Box div p .btn { padding:4px 10px 4px 5px; font-size:12pt; color:#fff; gap:5px; }
.sect5.tasks .sect5Box div p .btn svg { color:#fff; font-size:14pt; }


.filterBoxTop { display:flex; padding:0 10px; }
.filterBoxTop .formInputBox { padding:18px 5px; width:auto; }
.filterBoxTop .formInputBox .selectBox .selectName { border:1px solid #e5e5e5; min-width:130px; padding:6px; border-radius: 20px; }
.filterBoxTop .formInputBox .dropDownBox { min-width: 160px; }
.filterBoxTop .formInputBox .dropDownBox a { padding:8px 10px; width:auto; }

.questionBoxes { background-color: #fff; box-shadow: 0 0 5px rgba(0,0,0,0.1); }

.addQuestButtonBox { position:fixed; display:flex; justify-content: center; box-shadow:0 0 5px rgba(0,0,0,0.1); bottom:0; background-color: #fff; width:100%; padding:10px 0; left:0; right:0; margin:auto }
.addQuestButtonBox .btn { padding:5px 15px; font-size: 12pt; cursor: pointer; }
.addQuestButtonBox .btn:hover { opacity:0.8 }

/* .gameBox { width:100vw; height:100vh; background-image: url('../../images/game_bg.jpg'); background-repeat: no-repeat; background-size: cover; } */
.gameBox { width:100vw; height:100vh; }
.gameBox .close { position:fixed; top:10px; left:10px; font-size:40pt; }
.gameBox iframe { width:100vw !important; height:100vh !important; border:0; }

.gameListBox { position:relative }
.lockedBox { font-size:16pt; color:#000; padding:5px; position:absolute; z-index:0; width:100%; height:100%; background-color: rgba(255,255,255,0.5); }

.sectTasks .mainWidth { display:flex; flex-direction: row; min-height:200px; background-color: #fff; box-shadow:0 0 5px rgba(0,0,0,0.1) }
.sectTasks .left { width:250px; border-right:1px solid #eee; background-color: #f8f8f8; padding:15px;  }
.sectTasks .left h4 { font-size: 10pt; color:#555 }
.sectTasks .left nav { display:flex; flex-direction: column; margin-top:10px; }
.sectTasks .left nav a.mainCatsLink {  padding:4px 0; display:flex; align-items:center; gap:5px; color:#111; font-size:12pt; font-weight:400; }
.sectTasks .left nav a.mainCatsLink.selected { color:#ec4679 }
.sectTasks .right { flex:1; }

.subCatsBox { display:flex; gap:2%; flex-wrap: wrap; padding:4%; border-bottom:1px solid #eee;}
.subCatsBox h2 { width:100%; font-size:12pt; font-weight: 600;}
.subCatsBox a { width:18.2%; margin-top:20px; }
.subCatsBox a img { width:100%; height:auto; aspect-ratio:16/11; object-fit: cover; }
.subCatsBox a h3 { font-size:9pt; }

.productsInTask { padding:20px 4%; display:flex; gap:2%; flex-wrap: wrap;  }
.productsInTask h2 { width:100%; font-size:12pt; font-weight: 600;}
.productsInTask div { width:32%; margin-top:20px; background-color: #f9f9f9; }
.productsInTask div img { width:100%; height:auto; aspect-ratio: 1.6; object-fit: cover; }
.productsInTask div p { padding:5px 10px; }
.productsInTask div h3 { font-size:11pt; font-weight: 500; }
.productsInTask div span { font-size:10pt; color:#888 }