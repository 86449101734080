.activitiesHeadBox .btn { cursor: pointer; align-self: center; margin-right:10px; font-size: 11pt; gap:3px !important; padding:5px 10px 5px 5px !important; flex:unset !important; border:0 !important; color:#fff !important; }
.activitiesHeadBox .btn svg { font-size:16pt !important; color:#fff !important }

.gameListBox.activity p { padding:5px 10px !important; }
.gameListBox.activity span { margin:0 !important }

.gameListBox.activity .btn { font-size:11pt; padding:5px 10px; width:100%; justify-content: center;}
.gameListBox.activity div { width:unset !important; padding:10px; }

.gameListBox.activity { position:relative;  }
.gameListBox.activity:hover .editMenu {  display:flex; }

.gameListBox.activity .editMenu { display: none; position:absolute; justify-content: end; gap:4px; width:100%; padding:3px; z-index:1 }
.gameListBox.activity .editMenu svg { cursor: pointer; background-color:rgb(219, 65, 65); padding:4px; font-size: 20pt; border-radius: 50%; color:#fff; }
.gameListBox.activity .editMenu a:nth-child(2) svg { background-color: rgb(62, 166, 62); }