.mobileScreen { display:none; }

.mainWidth { width:960px; display:table; margin:auto; }
.btn { border:0;  background-color:#fd745a; color:#fff; border-radius:5px; font-size:14pt; font-weight:500; display:flex; align-items: center; }

.align-right { text-align: right !important; }
.align-left { text-align: right !important; }
.align-center { text-align: center !important; }

.green { background-color: #01b2bc !important; }
.blue { background-color: #6799ec !important; }
.gray { background-color: #999 !important; }
.orange { background-color: #ffa442 !important; }
.red { background-color: rgb(244, 67, 55) !important; }

button { cursor: pointer; }
.btn { cursor: pointer; outline:#01b2bc }
.btn:focus { box-shadow:inset 0px 0px 0px 2px #000 !important; }

.dataHead nav { display:flex; align-self:self-end; gap:10px; padding:0 10px;  padding-top:15px; background-color: #f5f5f5; border-bottom:1px solid #e5e5e5 }
.dataHead nav a { padding:10px; display:flex; color:#666; font-size:10pt; font-weight: 400;  }
.dataHead nav a.checked { border:1px solid #eee; border-bottom:0; color:#444; font-weight: 600; background-color: #fff; transform: translateY(1px); border-radius:6px 6px 0 0 }

.tabsSection { display:none; }
.tabsSection.active { display:block; }


.table { width:100%; border-spacing:0; border-left:1px solid #d7e1ed; border-bottom:1px solid #d7e1ed; }
.table thead tr td { font-weight: 600; }
.table tr td {  border-top:1px solid #d7e1ed; padding:8px 6px; text-align: center; color:#607188; font-size:10pt; font-weight: 400; vertical-align: middle; }
.tableTRList.notValid td:nth-child(1) { border-right:3px solid red }
.table tbody tr:nth-child(odd) { background-color: #eef5f9; }
.table .check { zoom:1.5; cursor: pointer; }
.table .options { font-size: 16pt; line-height: 100%;  }
.table .options.delete { color:#f33737 }

.tag { padding:3px 10px; border-radius: 20px; font-size: 10pt; display:table; margin:auto; background-color: #42a5f5; color:#fff; }

.noData { padding:30px; background-color: #f1f1f1; text-align: center; color:#555 }

table .tableOptions { background-color: #999; line-height: 0; font-size: 15pt; color:#fff; padding:4px 4px 0 4px; border-radius: 5px; }



@media only screen and (max-width: 1000px) {
    .mainWidth { width:100%; }

    .effect.small h1 { margin-top:0 !important; font-size:16pt !important; padding:13px; text-align: center; }
    .effect.small .headPath { margin-top:0 !important; font-size:16pt !important; padding:22px; text-align: center;  }
    .effect.small .headPath a { font-size:14pt;  }
    .effect.small .headPath a.main { font-size:14pt !important;  }

    .logo { padding:5px 10px !important; display:block; }
    .mobileScreen.menuCont { display:flex; gap:10px; align-items: center;  }
    .mobileScreen.menuCont .btn { padding:2px 15px; border-radius: 20px; }
    .mobileScreen.menuCont div { width:50px; position: relative;
        color: #fff;
        display: flex;
        align-items: flex-end; 
    }
    
    .mobileScreen.menuCont div img { width: 40px;
        aspect-ratio: 1;
        border-radius: 100px;
        object-fit: cover;
        border:1px solid #fff;
    }

    .mobileScreen .menuBtn { display:block; align-self:center; margin-right:10px; line-height: 50%; font-size:26pt; color:#fff; }
    .mobileScreen.menuCont div b {  
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #c62827;
        right: -5px;
        top: -5px;
        font-size: 8pt;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .mobileScreen.slideMenu { display:block; transition: transform 0.4s; transform: translateX(110%); position:fixed; top:0; right:0; width:80%; max-width: 360px; height:100vh; background-color: #fff; box-shadow: 0 0 10px rgba(0,0,0,0.2); z-index:5;  } */
    /* .mobileScreen.slideMenu.open { transform: translateX(0); } */
    
    .mobileScreen.slideMenu.open { display:block; }
    .mobileScreen.slideMenu .black { position:fixed; top:0; right:0; width:100vw; height:100vh; background-color: rgba(0,0,0,.5); z-index:4; }

    .mobileScreen.slideMenu .menuBox { transition: all 0.4s; transform: translateX(-110%); position:fixed; top:0; left:0; width:80%; max-width: 360px; height:100vh; background-color: #fff; box-shadow: 0 0 10px rgba(0,0,0,0.2); z-index:5; overflow:auto; }
    .mobileScreen.slideMenu.open .menuBox { transform: translateX(0);  }

    .mobileScreen.slideMenu .userImgIn { display:flex; gap:8px; align-items: center; border-bottom: 1px solid #ddd; padding:20px; background-color: #e1e9e9; }
    .mobileScreen.slideMenu .userImgIn img { width:50px; aspect-ratio: 1; object-fit: cover; border-radius: 50%; }
    .mobileScreen.slideMenu .userImgIn div { flex:1 }
    .mobileScreen.slideMenu .userImgIn div h3 { font-size:12pt; font-weight: 600; }
    .mobileScreen.slideMenu .userImgIn div h4 { font-size:10pt; font-weight: 300; color:#777;  }


    /* .mobileScreen.slideMenu .menuBox .allMenusMobile {  } */
    .mobileScreen.slideMenu .menuBox .allMenusMobile nav { border-top:1px solid #ddd; padding:20px 0; }
    .mobileScreen.slideMenu .menuBox .allMenusMobile nav a { padding:10px 20px; display:flex; align-items: center; gap:10px; }
    .mobileScreen.slideMenu .menuBox .allMenusMobile nav a svg { color:#555 }
    
    .effect { height:400px !important; }
    .effect.small { height:70px !important; }
    .effect .textEffect { max-width: 100% !important; padding:20px;  }
    .effect .textEffect h1 { font-size:26pt !important; }
    .effect .textEffect p { font-weight: 500 !important; font-size:14pt !important; text-shadow: 1px 1px #000; visibility: hidden;  }

    .firstElem { width:90%; margin:auto; margin-top:-10px !important; }
    .firstElem .mainWidth { flex-wrap: wrap;  }
    .firstElem .mainWidth a { flex-direction: column; width:50%; flex:none !important; border-bottom: 1px solid #eee;}
    .firstElem .mainWidth a img { align-self: center !important; }

    .boxLine { margin-left:10px !important; }
    .boxTitle { margin-left:10px !important; }
    .boxshort { margin-left:10px !important; display:block; }

    .sect1 .sectBox { display:block !important }
    .sect1 .sectBox .options { flex-direction: column; }
    .sect1 .sectBox .options a { width:100% !important; padding:15px; border-top:1px solid #ddd; height:auto !important; }
    .sect1 .sectBox .man { display:none; }


    .sect5 .sect5Box { flex-wrap: wrap; gap:2% !important; padding:15px; margin-top:20px !important; }
    .sect5 .sect5Box div { width:49% !important; flex:none !important; margin-bottom: 2%; }
    .sect5 .sect5Box div img { aspect-ratio: 16/11 !important; }

    .sect3 text { margin:30px 10px 0 10px !important; font-weight: 200 !important; font-size: 12pt !important; }
    .sect3 .sec3Box { flex-direction: column; }
    .sect3 .sec3Box a { padding:25px 15px; border-top:1px solid #444 }

    .footer { padding-top:50px !important; }
    .footer .mainWidth { flex-direction: column; }
    .footer .mainWidth .footerBox { width:100% !important; padding:20px 15px; border-bottom:1px solid #ddd; }

    .footerText { margin-top:0 !important }

    .cp { flex-direction: column; }
    .cp .containerBox { width:100% !important; }
    
    .quizzesList { width:100vw; overflow: auto; }
    .quizzesList table { min-width:max-content !important; }

    .quizzesList table td { max-width:300px; }

    .profileBox { width:94% !important; margin-top:0 !important; }

    .sect5 .sect5Box div text { font-size:11pt; }

    .firstElem.search { width:100%;  }

    .body { margin-top:0 !important }

    .filterBox.open { width:100%; }

    .quizPrevBox .questionsBox { padding:30px 20px !important }
    .quizPrevBox .quizInfo .left .moreInfo { flex-direction: column; gap:5px !important }

    .sect5.tasks { padding-top:20px; }

    .backGMain { padding-top:70px; background-position: right bottom; }

    header { position:fixed; z-index:1;  width: 100%; top:0; right:0;  }
    header nav { background-color: rgba(0, 0, 0, 0.6) !important; }

    .body.taskBox .containerBox { width:94% !important; margin:auto; }
    .body.taskBox .sectionInfo ul { padding:20px 15px !important; }
    .cp .containerBox .boxTitle { padding:0 !important }

    header nav .mainWidth .left, header nav .mainWidth .right { display:none !important; }

    .answersBox .letSqu b { width:51px !important; }

    .filterBoxTop .formInputBox .selectBox .selectName { min-width: auto !important; }
    
}