.questionsBoxSend .btn.fav { display:none; }
.questionsBoxSend .answersBox { display:none; }
.questionsBoxSend .qVideoPlayer iframe { display:none; }
.questionsBoxSend .qVideoPlayer p { display:none; }

.btnQuizSend { padding:5px 10px; margin-top:20px; font-size:12pt; cursor:pointer; }
.btnQuizSend:hover { opacity:0.8 }

.checkBoxTable { zoom:1.4; cursor: pointer; }

.statsBox { display:flex; gap:15px; }
.statsBox div { flex:1; background-color: #fff; box-shadow:0 0 3px rgba(0,0,0,0.2); padding:15px 20px ; border-radius: 10px; }
.statsBox div span { display:block; font-size: 10pt; color:#999 }
.statsBox div b { display:block; font-size: 30pt; color:#555; color:#fd745a }