.quizBody { background-image: url(../../images/93826.jpg); overflow:auto; width:100vw; height:100vh; background-position: top center; background-size: cover; display:flex; justify-content: center; align-items: center; background-attachment: fixed;  }

.quizBody .quizBox { background-color: rgba(255, 255, 255, 0.7); box-shadow:0 0 20px #00b5cf90; width:800px; min-height:600px; border-radius: 30px; display:flex; flex-direction: column; align-items: center; }
.quizBody .quizHead { width:100%; height:150px; }
.quizBody .quizHead img { border-radius: 30px 30px 0 0; object-fit: cover; width:100%; height:100%; }

.quizBody .quizBg { box-shadow:0 0px 5px #00b5cf50; background-color: #fff; width:90%; margin-bottom:20px; border-radius:5px;  }
.quizBody .quizBg .catBox { margin-top:-20px; }
.quizBody .quizBg .catBox span { display:table; margin:auto; background-color:#00b5cf; padding:4px 20px; border-radius:20px; color:#fff; }

.quizBody .quizBg h1 { padding:20px 30px; font-size: 12pt; font-weight: 600; color:#555 }