.successWhite { animation: Successopacity 0.5s ease; opacity:1; position:fixed; top:0; right:0; width:100vw; height:100vh; z-index:99;  display:flex; background-color: rgba(246,248,250,0.7); justify-content: center; align-items:flex-start; }
.successBox { animation: success 2.5s ease; transform: scale(0); border-bottom:5px solid #2cd640 ; display:flex; align-items: center; width:90%; max-width:400px; padding:20px; border-radius: 20px; background-color: #fff; box-shadow:0 0 15px rgba(0,0,0,0.1); margin-top:10px; gap:10px; }

@keyframes success {
    0%,100% {
        transform: scale(0);
    }
    10%,90% {
        transform: scale(1);
    }
}

@keyframes Successopacity {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.successBox.error { border-bottom:5px solid #e53434 ; }

.successBox p { width:40px; height:40px; border-radius:10px; background-color:#e3ffe4; display:flex; justify-content: center; align-items: center; }
.successBox.error p { background-color:#ffeaed; }

.successBox p b { color:#fff; background-color: #2cd640; display:flex; justify-content: center; align-items: center; width:22px; height:22px;  border-radius: 50%; padding:5px; }
.successBox.error p b { background-color:#e53434; }

.successBox div.text { flex:1; }
.successBox div.text h3 { color:#485164; font-size:11pt; font-weight:400; line-height:100%; }
.successBox div.text span { font-size:10pt; color:#555 }

.successBox a { cursor: pointer; }
.successBox a:hover { opacity:0.8 }