@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
* { font-family: "Cairo", sans-serif; font-weight:400; line-height:150%; box-sizing: border-box; }

body { padding:0; margin:0; direction:rtl; background-color: #f9f9f9; }

a { text-decoration: none; cursor: pointer; color:#555 }
a:hover { opacity:0.7 }
img { max-width:100%; }
ol,ul,li,p,h2,img,input,div,h1,h2,h3,h4,h5,h6,button,table,td,content,footer,textarea,input,audio,select,nav,text,b { font-size: 12pt; outline-width:0; padding:0px; margin:0px; }
li { list-style-type:none; }

table { border-spacing:0; }

*::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 15px;
}

.LoaderBox { position:fixed; top:0; right:0; z-index:100; width:100%; height:100%; display:flex; justify-content: center; align-items: center; }
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #42a5f5 #42a5f5 transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FF3D00 #FF3D00;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;
    border-color: #42a5f5 #42a5f5 transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      