.firstElem { margin-top:-70px; z-index: 1; }
.firstElem .mainWidth { background-color: #fff;  box-shadow: 0 0 10px rgba(0,0,0,0.1); display:flex; justify-content: space-between; }
.firstElem .mainWidth a { display:flex; gap:20px; border-right:1px solid #eee; flex:1; padding:25px 15px; color:#111; z-index:1; }
.firstElem .mainWidth a svg { font-size:26pt; color:#fd745a }
.firstElem .mainWidth a img { width:40px; height:auto; align-self: start; }
.firstElem .mainWidth a div { flex:1; }
.firstElem .mainWidth a div h2 { font-size: 12pt; line-height: 120%; font-weight: 600; color:#555; margin-bottom:5px; }
.firstElem .mainWidth a div p { font-size: 11pt; font-weight:300; color:#777 }

.shadow { box-shadow: 0 0 10px rgba(0,0,0,0.1); }

.sect1 { margin-top:50px; }
.sect1 .sectBox { display:flex;  }

.sect1 .sectBox .options { display:flex; flex-wrap: wrap; flex:1; align-self: start; gap:5%; align-items:flex-start; padding-bottom:10px; }
.sect1 .sectBox .options a { display:flex; width:46%;  gap:15px; height:100px; }
.sect1 .sectBox .options a svg { font-size:36pt; color:#e00667 }
.sect1 .sectBox .options a img { width:40px; align-self: start; }
.sect1 .sectBox .options a h4 { font-size:12pt; font-weight: 600; color:#444 }
.sect1 .sectBox .options a text { font-size:10pt; display:table; font-weight: 300; color:#999; }
.sect1 .sectBox .man { width:24%; align-self: end; }
.sect1 .sectBox .man img { display:table; }


.sect2 { padding:50px 0; background-color: #fff; border-top:1px solid #ddd; }
.sect2 .mainWidth { display:flex; gap:20px; }
.sect2 .mainWidth h5 { font-size: 20pt; font-weight: 00; }
.sect2 .mainWidth text { font-size: 13pt; color:#777; font-weight: 300; margin-top:10px;  display:block;}
.sect2 .mainWidth img { width:44%; aspect-ratio: 1.8; object-fit: cover; }

.sect3 { background-color: #222; padding:50px 0; }
.sect3 h6 { border-bottom:2px solid #ddd; width:315px; margin:auto; padding:0 40px; height:22px; }
.sect3 h6 span { background-color: #222; display:table; padding:0 10px; font-size: 20pt; font-weight: 600; letter-spacing: 5px; color:#fd745a }

.sect3 text { display:table; font-size:14pt; margin:auto;  margin-top:36px; font-weight: 300; color:#eee }

.sect3 .sec3Box { display:flex; gap:5%; margin-top:40px; }
.sect3 .sec3Box a { flex:1; }
.sect3 .sec3Box a svg { color:#fd745a; font-size:30pt; }
.sect3 .sec3Box a b { font-size:16pt; font-weight: 300; color:#eee }
.sect3 .sec3Box a p { font-size:12pt; font-weight:200; color:#999 }
.sect3 .sec3Box a span { font-size:10pt; font-weight:200; margin-top:10px; display:block; color:#ddd }

.sect5 { padding:40px 0; background-color: #fff; border-top:1px solid #eee; }

.sect5 .sect5Box { display:flex; gap:40px 5%; margin-top:40px; flex-wrap: wrap; }
.sect5 .sect5Box div { display:block; background-color: #f7f7f7; width:30%; padding:10px; border-radius: 10px; }
.sect5 .sect5Box div p {  }
.sect5 .sect5Box div img { width:100%; aspect-ratio: 16/9; color:#444; object-fit: cover; }
.sect5 .sect5Box div h3 { font-size: 12pt;  color:#111; font-weight: 600; }
.sect5 .sect5Box div h4 { font-size: 10pt;  color:#666; font-weight: 300; }
.sect5 .sect5Box div span { display:flex; justify-content: end; gap:10px; margin-top:10px; }
.sect5 .sect5Box div span small { display:flex; background-color: #ffa135; color:#fff; padding:4px 6px; font-size: 9pt; border-radius: 20px; }
.sect5 .sect5Box div text { color:#777; font-weight: 200; margin-bottom:10px; display:block; flex:1 }

.sect5.gray5 { background-color: #f9f9f9; }
.sect5.gray5 .sect5Box { gap:2%; }
.sect5.gray5 .sect5Box div { background-color: #fff; box-shadow: 0 0 5px rgba(0,0,0,0.1); width:23.2%;  }

.sect5.gray5 .gameListBox img {  aspect-ratio: 1; }

.sect6 { background-color: #fff; border-bottom:1px solid #eee; }
.sect6 .mainWidth hr { margin-top:40px; }
.sect6 .sect6Box { display:flex; align-items: center; margin-top:40px; }
.sect6 .sect6Box .right { display:flex; flex:1; }
.sect6 .sect6Box a { display:flex; flex:1; text-align: center; flex-direction: column; }
.sect6 .sect6Box a span { font-size:34pt; color:#e00667; line-height: 100%; }
.sect6 .sect6Box a text { font-size:10pt; color:#999; font-weight: 200; }

.sect6 .sect6Box .left {  }
.sect6 .sect6Box .left img { display:block; height:200px; }

.boxLine { width:150px; border:0; margin:auto; margin-bottom:15px; margin-top:10px; border-top:2px solid #1f94d8;  }
.boxTitle { font-size:20pt; color:#333;  font-weight: 800; text-align: center; line-height: 150%; text-transform: capitalize; }
.boxshort { color:#999; font-size: 11pt; font-family: "Readex Pro", serif; text-align: center; font-weight:300; }

.statsMainBox { padding:60px 0; z-index:1; position: relative; background-color: #fff; border-top:1px solid #eee }
.statsMainBox .mainWidth { display:flex; align-items: center;  padding: 0; gap:15px; }
.statsMainBox .mainWidth div { padding:30px 0; flex:1; display:flex; background-color: #ffa135; box-shadow: 0 0 10px rgba(0,0,0,0.1); flex-direction: column; align-items: center; border-radius: 5px; }
.statsMainBox .mainWidth div:nth-child(1) { border:0; }
.statsMainBox .mainWidth div span {font-size: 9pt; font-weight: 300; color:#fff }
.statsMainBox .mainWidth div b { font-weight: 400; font-size: 35pt; font-family: "Readex Pro", serif; color:#fff }
.statsMainBox .mainWidth div:nth-child(2) { background-color:#32b388 }
.statsMainBox .mainWidth div:nth-child(3) { background-color:#ff5e5e }
.statsMainBox .mainWidth div:nth-child(4) { background-color:#1f94d8 }

.sect5.gamesCont { display:flex; flex-wrap: wrap; padding:15px; gap:3%; padding:0; border:0; }
.sect5.gamesCont .sect5Box { padding:15px; }
.sect5.gamesCont .sect5Box .gameListBox { width:23.6%; margin-bottom:10px; border:1px solid #eee; }