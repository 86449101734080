.newGenInputIn {  }
.newGenInputIn .newGenTitle { padding:20px 25px; background-color: #f8f8f8; border-bottom:1px solid #e7e7e7; }

.newGenInputIn .formInputBox { padding:10px; min-width:100%; }
.newGenInputIn .formInputBox span { transform: translateY(0); margin-bottom:2px; color:#7c899c }
.newGenInputIn .formInputBox input, .newGenInputIn .formInputBox textarea { background-color: #fff; border: 1px solid #d3dbe5; border-radius:5px; padding:5px 10px; font-size:13pt; }
.newGenInputIn .formInputBox hr { display:none; }

.newGenOptions { padding:20px 25px; }
.newGenTable { display:flex;  }
.newGenTable strong { width:25px; font-size: 10pt; font-weight: 600; display:flex; align-items: center; }
.newGenTable div { flex:1 }
.newGenTable div b { font-weight: 600; font-size: 9pt;}
.newGenTable ul { width:80px; display:flex; justify-content: center; align-items: center; color:#9e9f9e; gap:5px; }
.newGenTable ul svg { cursor: pointer; }
.newGenTable ul svg:hover { color:#444 }

.newGenTable.genBody { margin-bottom:5px; }
.newGenTable.genBody div { border-top:1px solid #e5e5e5; border-right:1px solid #e5e5e5;  border-bottom:1px solid #d1d1d1; display:flex; align-items: center; }
.newGenTable.genBody div:nth-child(2) { border-left:1px solid #e5e5e5; }
.newGenTable.genBody div input { border:0; padding:6px; flex:1; font-size:11pt; }
.newGenTable.genBody div label { padding:0 5px; cursor: pointer; }
.newGenTable.genBody div label input { width:1px; height:1px; visibility:hidden }
.newGenTable.genBody div label:hover svg { color:#444 }
.newGenTable.genBody div label svg { font-size:14pt; transform: translateY(2px); color:#9e9f9e }

.newGenTable.genBody div img { flex:1 ; max-width:34px; aspect-ratio: 1; display:table; object-fit: cover; padding:2px; }

.newGenMore { display:flex; align-items: center; margin-left: 25px; margin-top:10px; font-size: 10pt; font-weight: 600; gap:5px; }
.newGenOptions .btn { padding:5px 15px; margin-top:15px; font-size:12pt; }