.quizPrevBox { display:flex; flex-direction: column; min-height:0; height:90vh; }
.quizPrevBox .quizInfo { background-color: #f9f9f9; }

.quizPrevBox .quizInfo .left { display:flex; padding:15px 10px;  gap:10px; }
.quizPrevBox .quizInfo .left img { width:120px; aspect-ratio: 1.2; object-fit: cover; border-radius: 5px; }
.quizPrevBox .quizInfo .left .infoText {  }
.quizPrevBox .quizInfo .left .infoText h2 { font-size: 14pt; font-weight: 600; color:#555 }
.quizPrevBox .quizInfo .left .infoText p {  font-size: 11pt; font-weight: 300; color:#555 }

.quizPrevBox .quizInfo .left .moreInfo { margin-top:4px; gap:10px; display:flex; }
.quizPrevBox .quizInfo .left .moreInfo text { display:flex; align-items: center; background-color:rgb(253, 116, 90); color:#fff; border-radius: 20px; padding:2px 10px; font-size:10pt;}
.quizPrevBox .quizInfo .left .moreInfo text b { font-size:9pt; font-weight: 600; margin-left:2px;}
.quizPrevBox .quizInfo .left .moreInfo text svg { margin-left:3px; }

.quizPrevBox .questionsBox { padding:30px 50px; border-top:1px solid #e9e9e9; display:flex; flex-direction: column; gap:15px; flex:1; min-height:0; overflow:auto }
.quizPrevBox .questionsBox .question { position:relative; padding:0; border-radius: 5px; box-shadow: 0 0 5px rgba(253, 116, 90, 0.5); cursor:pointer; }
.quizPrevBox .questionsBox .question:hover { box-shadow: 0 0 5px rgba(253, 116, 90, 0.8); }
.quizPrevBox .questionsBox .question.checked { box-shadow: 0 0 5px rgba(1, 178, 188, 0.5); }

.quizPrevBox .questionsBox .question h3 { padding:5px 15px 0 0; font-size:12pt; font-weight: 600; color:#555 }
.quizPrevBox .questionsBox .question .notesBox { padding:10px 15px 0 0; color:#888; font-size:10pt; font-weight: 300; }
.quizPrevBox .questionsBox .question .notesBox b { color:#666; font-weight: 500; font-size:10pt; }

.quizPrevBox .questionsBox .question .checkBoxQ { display:flex; justify-content: space-between; text-align: right; padding:5px; }
.quizPrevBox .questionsBox .question .checkBoxQ b { display:flex; background-color: #111; transform: translate(5px, -5px); width:30px; height:30px; color:#fff; align-items: center; justify-content: center; border-radius: 0px 10px 0 10px; }
.quizPrevBox .questionsBox .question .checkBoxQ svg { color:#01b2bc; font-size: 22pt; }

.quizPrevBox .questionsBox .question .answersBox { display:flex; flex-direction: column; margin-top:20px; margin-bottom:30px; gap:6px; padding:0 15px; }
.quizPrevBox .questionsBox .question .answersBox label { display:flex; align-items: center; gap:5px; background-color: #fff;  border-radius:5px; padding:0 10px; font-size: 11pt; font-weight: 300; color:#555 }
.quizPrevBox .questionsBox .question .answersBox label svg.radio { color:#999; font-size: 14pt; }
.quizPrevBox .questionsBox .question .answersBox label svg.radio.on { color:rgb(253, 116, 90) }

.quizPrevBox .questionsBox .question .qVideoPlayer { padding:15px; }
.quizPrevBox .questionsBox .question .qVideoPlayer iframe { width:100%; aspect-ratio: 16 /9; }
.quizPrevBox .questionsBox .question .qVideoPlayer p { font-size:10pt; font-weight: 300; color:#777; padding:0 15px; }

.quizPrevBox .quizBtnBox { background-color: #f9f9f9; border-top:1px solid #e9e9e9; padding:15px; display:flex; justify-content: flex-end; gap:5px; }
.quizPrevBox .quizBtnBox .btn { padding:6px 15px; font-size:12pt; font-weight: 400; cursor: pointer; }
.quizPrevBox .quizBtnBox .btn:hover { opacity:0.8 }

.quizPrevBox .questionsBox .question .fav { background-color: #999; padding:4px; position:absolute; right:10px; bottom:10px; }
.quizPrevBox .questionsBox .question .fav.on { background-color: #01b2bc;  }

.questionComments { padding:0 15px; background-color: #f9f9f9; margin-top:20px; border-top:1px solid #e7e7e7; border-radius:0 0 5px 5px; display:flex; justify-content: space-between; align-items: center; }
.questionComments .formInputBox  {  width:100px; padding:10px }
.questionComments .formInputBox input { border-radius:5px;  font-size:16pt; text-align: center; background-color: #fff; border:1px solid #e4e4e4; }
.questionComments .formInputBox span { transform: translateY(0); }
.questionComments .formInputBox hr { display:none; }



.questionComments .formInputBox .dropDownBox input { display:none; }


.kettersSplit { padding:20px; }
.kettersSplit a { width:30px; aspect-ratio: 1;  }
.answerLetTr { text-align: center; margin-top:10px;  }
.answerLetTr b { font-size:30pt; letter-spacing: 5px; text-transform:uppercase; color:rgb(253, 116, 90); font-weight: 600; }

.storyBox { padding:10px; border-radius: 5px; box-shadow: 0 0 5px rgba(253, 116, 90, 0.5); }
.storyBox strong { font-weight: 800; }
.storyBox h2 { margin-bottom:5px; font-size:14pt; font-weight: 600; }

.filterQuestionsBox { border-top:1px solid #eee; background-color: #f1f1f1; padding:5px 10px ; display:flex; justify-content: space-between; align-items: center; }
.filterQuestionsBox input { width:40%; padding:5px; background-color: #fff; border:1px solid #ddd; border-radius: 5px; }
.filterQuestionsBox a { color:#2487ae; text-decoration: underline; }