.popupBody.newQuestion .steps { display: none; }
.popupBody.newQuestion .steps.active { display: block; }

.popupBody.newQuestion .answersBox label { padding:6px; background-color: #fff; box-shadow: 0 0 4px rgba(0,0,0,0.2); display:flex; max-width: 420px; align-items: center; border-radius: 20px; margin-top:10px; }
.popupBody.newQuestion .answersBox label input { flex:1; border:0; padding:5px 10px; font-size:10pt }
.popupBody.newQuestion .answersBox label .ico { cursor: pointer; font-size: 16pt; color:#999 }
.popupBody.newQuestion .answersBox label .ico:hover { color:#ee4d4d }
.popupBody.newQuestion .answersBox label .check { font-size: 24pt; color:#999; line-height: 0; margin-left:2px;border-radius:5px; border-radius:50% } 
.popupBody.newQuestion .answersBox label .check.on { color:#265de6; } 


.popupBody.newQuestion .answersBox .btn { border-radius: 20px;  margin-top:10px; margin-right:140px; padding:5px; padding-left:10px; }



.popupBody.newQuestion .answersBox { margin-top:0; background-color: #fff; border:1px solid #eee; padding:10px 20px; border-radius:10px; }
.popupBody.newQuestion .answersType { margin-top:10px; padding-left:20px; display:flex; gap:8px; }
.popupBody.newQuestion .answersType a { padding:6px 10px; background-color: #f9f9f9; border-radius: 5px 5px 0 0; border:1px solid #eee; font-size: 10pt;  }
.popupBody.newQuestion .answersType a.checked { transform: translateY(1px); background-color: #fff; border-bottom:0; font-weight: 600;  }

.buttonsDataPopUp { display:flex; padding:20px 10px; justify-content: end; gap:5px }
.buttonsDataPopUp .btn { padding:6px 15px 6px 6px; font-size:11pt; display:flex; align-items: center; gap:5px }