.formInputBox { display:block; width:100%; max-width:400px; padding:25px 10px; position:relative; }
.formInputBox span { height:18px; transition: all 0.1s; display:block; font-size:12px; transform: translateY(18px); color:#7c899c; font-weight: 600; }
.formInputBox span b { font-weight: 600; font-size: 9pt; color:#f05a56 }
.formInputBox input, .formInputBox textarea { width:100%; padding:3px 0; border:0; background-color: transparent; border-bottom:1px solid #d3dbe5; color:#555; font-size:14px; font-weight: 400; }
.formInputBox textarea { margin:0; display:block; min-height:80px; }
.formInputBox.focused span { transform: translateY(0); color:#42a5f5; }
.formInputBox hr { transition: all 0.3s; position:absolute; width:95%; transform: scaleX(0); border:0; padding:0; margin:0; border-bottom:1px solid #42a5f5 }
.formInputBox.focused hr { transform: scaleX(1);  }

.formInputBox.error span { color:#f05a56; }
.formInputBox p { display:none }
.formInputBox.error p { display:block; font-size:10pt; color:#f05a56; margin-top:2px; }
.formInputBox.error hr { border-color: #f05a56;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.selectBox .selectName { cursor: pointer; display: flex; justify-content: space-between; align-items: center; padding:3px 0; border-bottom:1px solid #d3dbe5; color:#555; font-size:14px; font-weight: 400; }
.selectBox svg{ font-size:14pt; }

nav.dropDownBox { transition: all 0.4s; overflow:hidden; z-index:1; height:0; position:absolute; top:50px; width:100%; background-color:#fff; box-shadow:0 0 5px rgba(0,0,0,0.1); }
nav.dropDownBox.open { height:auto;  }
nav.dropDownBox.open div { overflow:auto; max-height:200px; }
nav.dropDownBox input { padding:10px; border-bottom:1px solid #eee; background-color: #f9f9f9; font-size:11pt; }
nav.dropDownBox a, nav.dropDownBox label { display:block; padding:8px 20px; font-size: 10pt; }
nav.dropDownBox a:hover, nav.dropDownBox label:hover, nav.dropDownBox a.selected { background-color:#4fa0ff; color:#fff; }

.dropDownBox .multiSelectBox label { display:flex; gap:5px; align-items: center; }
.dropDownBox .multiSelectBox label svg { font-size:16pt; color:#4fa0ff }
.dropDownBox .multiSelectBox label:hover svg { color:#fff }

.formInputBox .uploadForm.Img { width:150px; aspect-ratio:1; object-fit:cover; border-radius: 10px; cursor: pointer; border:2px solid #eee; }
.formInputBox .uploadForm.Img:hover { opacity:0.7 }

.formInputBox .uploadField { visibility: hidden; width:1px; height:1px; } 

.focusedSelect:focus { box-shadow:inset 0px 0px 0px 2px #000 !important; }