.popupBox { animation: popup 0.2s linear; display:flex; justify-content: center; align-items: center; opacity:1; position:fixed; top:0; right:0; width:100vw; height:100vh; padding:10px; background-color: rgba(0,0,0,0.8); z-index:9;  }
@keyframes popup {

    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.popupBox .popup { animation: popupBox 0.2s linear; transform: scale(1); background-color: #fff; box-shadow:0 0 30px rgba(0,0,0,0.3); width:96%; max-height:80vh; display:flex; flex-direction: column; min-height: 0; }
@keyframes popupBox {

    0% {
        transform:scale(0);
    }
    100% {
        transform:scale(1);
    }
}


.popupBox .popup .closePop { cursor: pointer; position:absolute; right:-15px; top:-15px; width:26px; height:26px; background-color:red; display:flex; justify-content: center; align-items: center; color:#fff; border-radius: 50%; }
.popupBox .popup .closePop:hover { opacity:0.8 }
.popupBox .popup h1 { display:flex; align-items: center; gap:5px; font-size:16pt; font-weight:600; padding:10px 20px; color:#fff; background-color: #fd745a; }
.popupBox .popup h1 svg { font-size:24pt; cursor: pointer; }
.popupBox .popup h1 svg:hover { opacity:0.7 }
.popupBox .popup .popupBody { flex:1; overflow: auto; }
.popupBox .popup .popupBody form { padding:20px; }
